<template>
    <div class="single-image-module-container">
        <div
            v-if="config.isBackground"
            class="background-container"
            :style="'background-image: url('+ getImg(config.image) +')'"
            @click="openLink"
        />
        <div 
            class="it-image-content"
            v-else
        >
            <img v-lazy="getImg(config.image)" @click="openLink" alt="No Image added to Single-Image-Module">
        </div>
    </div>
</template>

<script>

    import * as imgManager from "@/store/imgManager";
    export default {
        props: ["config"],
        methods:{
            getImg(image){
                let img = imgManager.getImage(image);
                if(img){
                    if(img.path){
                        return img.path;
                    }else{
                        return img.file;
                    }
                }
            },
            openLink(){
                if(this.config.link && this.config.link != ""){
                    window.open(this.config.link,'_blank');
                }
            }
        }
    }
    
</script>
